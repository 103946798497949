// Globals
@import './globals/vars';
@import './globals/fonts';
@import './globals/cookie-notice';

// Third-Party
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

// Base
@import './globals/base';

// Layout
@import './layout/header';
@import './layout/hero';
@import './layout/dashed-line';
@import './layout/instagram-slider';
@import './layout/collage';
@import './layout/textblock';
@import './layout/accordion';
@import './layout/button_icons';
@import './layout/quote';
@import './layout/footer';
