// Base
$base-width: .8rem;

// Boxed
$full: 1920px;
$boxed: 1400px;

//Font
$font-headline: 'Nothing You Could Do';
$font-text: 'Fira Sans';

// Colors
$green: #3C6025;
$orange: #FF8736;
$gray: #353535;

// Transition
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1440px);
