  /** About **/
#about {
  display: flex;
  justify-content: center;
  padding-bottom: 13*$base-width; }

.wrapper_about {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1364px;
  align-items: center;
  @media only screen and (max-width: 1500px) {
    padding: 0 20px; }
  @media only screen and (max-width: 1200px) {
    max-width: 1050px;
    flex-direction: column-reverse; } }

.about_image {
  width: 50%;
  img {
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 0px 50px #00000026;
    width: 75*$base-width;
    height: 75*$base-width;
    object-position: 58% 50%;
    @media only screen and (max-width: 1300px) {
      width: 68.75*$base-width;
      height: 68.75*$base-width; } }
  @media only screen and (max-width: 1200px) {
    padding-right: 0;
    padding-bottom: 25px;
    padding-top: 15*$base-width;
    img {
      border-radius: 0%;
      width: 100%;
      height: 100%; } }
  @media only screen and (max-width: 1200px) {
    width: 100%; } }

.about_textblock {
  width: 50%;
  @media only screen and (max-width: 1200px) {
    width: 100%; } }
.about_signature {
  p {
    font-family: $font-headline;
    font-size: 2.5rem;
    color: $green;
    padding-bottom: 6*$base-width; } }

/**Sub**/

.textblock {
  h1 {
    color: $green; }
  .wrapper_textblock {
    display: flex;
    flex-direction: row;
    max-width: 1364px;
    align-items: center;
    margin: auto;
    @media only screen and (max-width: 1500px) {
      padding: 0 20px;
      max-width: 1250px; }
    @media only screen and (max-width: 1200px) {
      flex-direction: column-reverse; } }

  .textblock_image {
    padding-right: 11.25*$base-width;
    img {
      width: 75*$base-width;
      height: 75*$base-width;
      border-radius: 50%;
      object-fit: cover;
      object-position: 17% 37%;
      box-shadow: 0px 0px 50px #00000026;
      @media only screen and (max-width: 1500px) {
        width: 65*$base-width;
        height: 65*$base-width; }
      @media only screen and (max-width: 1200px) {
        border-radius: 0%;
        max-width: 100%;
        min-height: auto;
        margin-top: 5*$base-width; } }
    @media only screen and (max-width: 1200px) {
      padding-right: 0;
      width: 100%;
      padding-top: 10*$base-width;
      img {
        margin-top: 0;
        border-radius: 0%;
        width: 100%;
        max-height: 500px;
        object-fit: cover;
        object-position: center; } }

    &.corners {
      img {
        border-radius: 0;
        height: auto;
        @media only screen and (max-width: 1200px) {
          max-height: 500px;
          object-fit: cover;
          object-position: center; } } } }

  .p_textblock_first {
    width: 100%; }

  .p_textblock {
    max-width: 674px;
    width: 100%;

    @media only screen and (max-width: 1200px) {
      max-width: none; }

    p:last-child {
      padding-bottom: 0; }

    .about_signature {
      p {
        font-family: $font-headline;
        font-size: 2.5rem;
        color: $green;
        padding-bottom: 6*$base-width; } } }

  &.onlytext {
    padding-bottom: 18.75*$base-width;
    @media only screen and (max-width: 768px) {
      padding-bottom: 0; }
    .wrapper_textblock {
      align-items: start;
      @media only screen and (max-width: 1500px) {
        padding: 0 20px;
        max-width: 1250px; }
      @media only screen and (max-width: 1200px) {
        max-width: 950px; }
      @media only screen and (max-width: 768px) {
        flex-direction: column; }

      .logo_contact {
        h3 {
          padding-bottom: 1rem;
          p {
            padding-bottom: 0.5rem;
            &.uppercase {
              padding-bottom: 2rem; } } } } }
    .adress {
      p {
        padding-bottom: 1rem; } }

    .contactdata {
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      .contacticon {
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        a {
          font-size: 2rem;
          line-height: 1.5;
          color: #353535;
          font-weight: 300; }
        .icon {
          padding-right: 1rem;
          width: 45px;
          svg {
            max-height: 30px;
            width: 100%;
            fill: $orange; }
          &.lighter {
            svg {
              stroke: #fff;
              stroke-width: 12px; } } } } }

    .opening {
      table {
        tr {
          td {
            font-size: 2rem;
            line-height: 1.5;
            color: #353535;
            padding-bottom: 2rem;
            font-weight: 300;

            &.demi {
              font-weight: 500;
              padding-right: 2rem; }

            &.gap {
              padding-left: 6 * $base-width; } } } } }

    .textblock_wrapper {
      max-width: 1364px;
      margin: auto;
      @media only screen and (max-width: 1500px) {
        padding: 0 20px;
        max-width: 1250px; }
      @media only screen and (max-width: 1200px) {
        max-width: 900px; }
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse; }

      h1 {
        text-align: left; }
      .textblock_section {
        padding-bottom: 4rem;
        p {
          padding-bottom: 2rem;
          a {
            color: #353535; } }
        &.contact {
          p {
            padding-bottom: 1rem; } } } } }
  .accordion__table {
    display: flex; }
  .accordion__time {
    padding-right: 50px; } }

#burnout_textblock {
  padding-bottom: 150px; }

.kk_logo {
  img {
    padding-top: 20px;
    max-width: 300px; } }

a.link {
  text-decoration: underline;
  color: $green; }
