footer {
  background-color: $green;
  width: 100%;
  height: 6.8*$base-width;
  display: flex;
  @media only screen and (max-width: 600px) {
    height: 100%; } }

#footer {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    padding: 10px 0; }

  ul {
    display: flex;
    flex-direction: row;
    column-gap: 4*$base-width;
    align-items: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse; } }
  li {
    list-style-type: none;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 1.5rem;
      text-transform: none;
      font-weight: 200;
      @media only screen and (max-width: 600px) {
        padding: 2*$base-width 0; }
      &:hover,
      &.active {
        text-decoration: underline; }
      svg {
        margin-right: 10px; } } } }

.footer__dashed__line {
  border-right: 1px dashed #707070;
  width: 0.1*$base-width;
  height: 2.5*$base-width;
  @media only screen and (max-width: 600px) {
    display: none; } }
