html {
    font-size: 10px; }
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

body {
    font-family: $font-text; }

section {
    max-width: 1920px;
    width: 100%;
    margin: auto; }

.uppercase {
    text-transform: uppercase; }

.bg-orange {
    a {
        color: #fff;
        background-color: $orange; } }

.container {
    max-width: 1307px;
    margin: auto; }

h1 {
    font-size: 7rem;
    color: #fff;
    line-height: 1.25;
    text-align: center;
    font-family: $font-headline;
    padding-bottom: 5*$base-width;
    font-weight: bold;
    text-shadow: 0px 3px 10px #00000040;
    @media only screen and (max-width: 992px) {
        font-size: 6rem; }
    @media only screen and (max-width: 768px) {
        font-size: 5rem; }
    @media only screen and (max-width: 576px) {
        font-size: 4rem; } }

h2 {
    color: $green;
    font-family: $font-headline;
    font-size: 4rem;
    font-weight: bold;
    padding-bottom: 2*$base-width;
    @media only screen and (max-width: 992px) {
        font-size: 3.5rem; }
    @media only screen and (max-width: 576px) {
        font-size: 3rem; } }

h3 {
    color: $green;
    font-family: $font-headline;
    font-size: 3rem;
    padding-bottom: 2.5rem;
    padding-top: 4rem; }

p {
    font-size: 2rem;
    line-height: 1.5;
    color: $gray;
    padding-bottom: 2rem;
    font-weight: 300; }

p strong {
    font-weight: 500;
    padding-bottom: 1.6rem; }

a {
    text-decoration: none;
    cursor: pointer; }

.button__outline {
    a {
        border-radius: 4*$base-width;
        padding: 2*$base-width 3*$base-width;
        border: 2px solid $green;
        text-decoration: none;
        color: $green;
        font-size: 2rem;
        color: $green;
        @media only screen and (max-width: 992px) {
            padding: 1.8*$base-width 2.8*$base-width; }
        &:hover {
            color: #fff;
            background-color: $green;
            transition: .2s ease-in;
            cursor: pointer; }

        a {
            color: #fff;
            font-size: 1.8rem; } } }
