#cookie-banner {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(0,0,0,.66);
    display: none;

    .cookie-content {
        width: 100%;
        max-width: 325px;
        background: $orange;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 4 * $base-width;
        p {
            color: #fff;
            font-size: 1.6rem; }

        @media only screen and (min-width: #{map-get($breakpoints, s)}) {
            max-width: 480px; }

        @media only screen and (min-width: #{map-get($breakpoints, m)}) {
            max-width: 720px; }

        .flex {

            @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                display: flex;
                justify-content: center; }

            a {
                padding: 2 * $base-width;
                text-align: center;
                display: block;
                margin: 0;
                font-family: $font-text;
                color: #fff;

                &#accepted {
                    margin-bottom: 2 * $base-width;
                    font-size: 1.4rem;
                    border: 1px solid #fff;
                    background-color: #fff;
                    color: #353535; }

                &#decline {
                    width: 40%;
                    font-size: 1.4rem;
                    font-family: $font-text;
                    color: #fff;
                    border: 1px solid #fff;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100%; } }

                @media only screen and (min-width: #{map-get($breakpoints, m)}) {
                    padding: 2 * $base-width 10 * $base-width;

                    &#accepted {
                        margin-bottom: 0;
                        width: 75%;
                        margin-right: 2 * $base-width; }

                    &#decline {
                        padding: 2 * $base-width 4 * $base-width;
                        width: 25%; } } } } } }
