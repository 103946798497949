@font-face {
  font-family: 'Nothing You Could Do';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/nothing-you-could-do-v13-latin-regular.woff2'),
  url('../../fonts/nothing-you-could-do-v13-latin-regular.woff') {} }

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/fira-sans-v15-latin-200.woff2') format('woff2'),
  url('../../fonts/fira-sans-v15-latin-200.woff') format('woff') {} }

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/fira-sans-v15-latin-300.woff2') format('woff2'),
  url('../../fonts/fira-sans-v15-latin-300.woff') format('woff') {} }

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/fira-sans-v15-latin-regular.woff2') format('woff2'),
  url('../../fonts/fira-sans-v15-latin-regular.woff') format('woff') {} }
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/fira-sans-v15-latin-500.woff2') format('woff2'),
  url('../../fonts/fira-sans-v15-latin-500.woff') format('woff') {} }

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/fira-sans-v15-latin-600.woff2') format('woff2'),
  url('../../fonts/fira-sans-v15-latin-600.woff') format('woff') {} }
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/fira-sans-v15-latin-800.woff2') ,
  url('../../fonts/fira-sans-v15-latin-800.woff') {} }

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/fira-sans-v15-latin-900.woff2'),
  url('../../fonts/fira-sans-v15-latin-900.woff') {}

  @font-face {}
  font-family: 'Abel Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/abel-v18-latin-regular.woff'); }
