#quote {
  .wrapper {
    max-width: 1000px;
    margin: 0 auto 16rem;
    text-align: center;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem; }
    @media only screen and (max-width: 650px) {
      padding-top: 15rem; } } }
