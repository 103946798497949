#slider {
    padding-bottom: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 650px) {
        padding: 0 2.5*$base-width; }
    .slider_headline {
        h2 {
            padding-bottom: 5.5rem; } }

    .slider {
        max-width: 1362px;
        padding-bottom: 6rem;
        @media only screen and (max-width: 1570px) {
            max-width: 920px; }
        @media only screen and (max-width: 1110px) {
            max-width: 800px; }
        @media only screen and (max-width: 992px) {
            max-width: 445px; }
        @media only screen and (max-width: 650px) {
            max-width: 350px; }
        @media only screen and (max-width: 576px) {
            max-width: 300px; }
        @media only screen and (max-width: 450px) {
            max-width: 250px; } }
    .owl-stage-outer {
        position: relative; }

    .owl-item a {
        &:hover {
            .overlay {
                opacity: 0.66;
                transition: all .4s ease-in-out; } } }

    .owl-nav {
        img {
            width: 2rem;
            filter: invert(30%) sepia(69%) saturate(395%) hue-rotate(53deg) brightness(91%) contrast(90%);
            @media only screen and (max-width: 450px) {
                width: 1.5rem; } } }


    .owl-prev, .owl-next {
        position: absolute;
        top: 40%;
        color: $green;
        border: 2px solid $green;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
        @media only screen and (max-width: 650px) {
            width: 5rem;
            height: 5rem; }
        @media only screen and (max-width: 450px) {
            width: 4rem;
            height: 4rem;
            top: 35%; }

        &:hover {
            background-color: $green;
            transition: all .2s ease-in-out;
            img {
                filter: invert(100%);
                transition: all .2s ease-in-out; } } }

    .owl-prev {
        left: -10rem;
        @media only screen and (max-width: 650px) {
            left: -8rem; }
        @media only screen and (max-width: 470px) {
            left: -6rem; }
        @media only screen and (max-width: 450px) {
            left: -5rem; } }


    .owl-next {
        right: -10rem;
        @media only screen and (max-width: 650px) {
            right: -8rem; }
        @media only screen and (max-width: 470px) {
            right: -6rem; }
        @media only screen and (max-width: 450px) {
            right: -5rem; } }


    .image {
        height: 443px;
        @media only screen and (max-width: 650px) {
            height: 350px; }
        @media only screen and (max-width: 576px) {
            height: 300px; }
        @media only screen and (max-width: 450px) {
            height: 250px; } }

    .owl-carousel .owl-item img {
        height: 100%; }

    .owl-carousel .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, $orange, $green);
        opacity: 0; } }
