#wrapper_button_icons {
  display: flex;
  flex-direction: column;
  row-gap: 0.5*$base-width;
  top: 186px;
  left: 0;
  position: fixed;
  @media only screen and (max-width: 1024px) {
    display: none; }
  .icon_and_content {
    display: flex; }

  a, p {
    color: $gray;
    font-weight: 400;
    font-size: 2rem;
    @media only screen and (max-width: 992px) {
      font-size: 1.8rem; } } }

.button__icon {
  background-color: $orange;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4rem;
    height: 4rem;
    @media only screen and (max-width: 992px) {
      width: 3.5rem;
      height: 3.5rem; } }

  @media only screen and (max-width: 992px) {
    width: 50px;
    height: 50px; } }

.button__icon__clock {
  &:hover {
    + .button__icon__content {
      display: flex;
      flex-direction: column; } } }

.button__icon__content {
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 0px 7px #00000026;
  justify-content: center;
  opacity: 0;
  display: none;
  p {
    padding-bottom: 0.5rem;
    &:last-child {
      padding-bottom: 0; } }
  strong {
    max-width: 8rem;
    width: 100%;
    display: inline-block; } }

.button__icon__content__phone {
  width: 191px;
  height: 60px;
  @media only screen and (max-width: 992px) {
    height: 50px;
    width: 155px; } }

.button__icon__content__mail {
  width: 269px;
  height: 60px;
  @media only screen and (max-width: 992px) {
    width: 215px;
    height: 50px; } }

.button__icon__content__clock {
  align-items: start;
  padding: 0 3 * $base-width;
  strong {
    padding-bottom: 0; } }

.icon_and_content {
  &:hover {
    .button__icon {
      background-color: $green;
      transition: all .5s ease-in-out; }
    .button__icon__content {
      display: flex;
      flex-direction: column;
      transition: all .5s ease-in-out;
      opacity: 1; } } }


.icon_and_content {
  &:hover {
    .button__icon__clock {
      transition: all .5s ease-in-out;
      background-color: $green; } } }
