/** Index Page **/
#bg-image {
  padding: 100px 0;
  @media only screen and (max-width: 1400px) {
    padding: 80px 0; }
  @media only screen and (max-width: 992px) {
    padding: 60px 0; }

  .background_images {
    height: 850px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    .image-placeholder {
      width: 100%;
      position: relative;
      display: block;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } }
    svg.wave {
      width: 100%;
      position: absolute;
      bottom: -1px; }
    @media only screen and (max-width: 1600px) {
      height: 650px;
      background-position: 60% 42%; }
    @media only screen and (max-width: 992px) {
      height: 550px;
      background-position: 60% 35%; }
    @media only screen and (max-width: 768px) {
      height: 450px;
      background-position: 60% 30%; }
    @media only screen and (max-width: 576px) {
      height: 400px; } }

  .overlay__start {
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(360deg, #FF8736 0%, #54545400 100%);
    opacity: 0.9; }

  .wrapper_title {
    position: absolute;
    max-width: 1088px;
    bottom: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 576px) {
      bottom: 40px; }
    .title {
      display: flex;
      max-width: 950px;
      @media only screen and (max-width: 992px) {
        max-width: 800px; }
      @media only screen and (max-width: 768px) {
        max-width: 700px;
        padding: 0 20px; } }

    .video {
      z-index: 4;
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1000;
        p {
          padding-bottom: 0; }

        &:hover {
          .video__playbutton {
            background-color: $orange;
            border: 3px solid $orange;
            cursor: pointer; }
          .video__text {
            cursor: pointer; } } }

      .video__text {
        margin-left: 2*$base-width;
        color: #fff;
        text-transform: uppercase;
        font-size: 2rem; }

      .video__playbutton {
        border-radius: 50%;
        border: 3px solid #fff;
        width: 8rem;
        transition: $transition;
        height: 8rem;
        box-shadow: 0px 0px 10px #00000020;
        position: relative;
        @media only screen and (max-width: 992px) {
          border: 2px solid #fff; }

        img {
          width: 2*$base-width;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-35%, -50%); } } } } }

/** Video Overlay **/
.fancybox-content {
  background: $orange !important;
  .fancybox-video {
    border: 5px solid $orange !important; }
  .fancybox-close-small {
    background: $orange;
    border-radius: 50%;
    right: -20px;
    top: -25px;
    opacity: 1;
    color: #fff; }

  /** Subpages **/ }

.hero {
  padding-top: 100px;
  @media only screen and (max-width: 1400px) {
    padding-top: 80px; }
  @media only screen and (max-width: 992px) {
    padding-top: 60px; }
  .background_images {
    background-position: 50% 50%;
    height: 550px;
    margin: auto;
    background-repeat: no-repeat;
    margin-bottom: 120px;
    position: relative;
    .image-placeholder {
      width: 100%;
      display: block;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } }
    .image-placeholder.personal {
      img {
        object-position: 50% 91%; } }
    .image-placeholder.burnout {
      img {
        object-position: 50% 80%; } }
    .image-placeholder.physiotherapie {
      img {
        object-position: 50% 42%; } }
    .image-placeholder.about {
      img {
        object-position: 50% 67%; } }
    @media only screen and (max-width: 1200px) {
      height: 400px;
      margin-bottom: 80px; }
    @media only screen and (max-width: 768px) {
      height: 350px;
      margin-bottom: 60px; }
    @media only screen and (max-width: 576px) {
      height: 250px;
      margin-bottom: 40px; }

    svg.wave {
      width: 100%;
      position: absolute;
      bottom: -1px; } } }

/** Personal Training **/
#training {
  .background_images {
    background-position: 50% 85%;
    @media only screen and (max-width: 1200px) {
      background-position: 50% 80%; } } }

/** Über mich **/
#about-me {
  .background_images {
    background-position: 50% 20%; } }
