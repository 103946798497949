#collage {
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #fff;
  height: 100%;
  @media only screen and (max-width: 1200px) {
    border-bottom: none; }
  @media only screen and (max-width: 768px) {
    padding-top: 12.5*$base-width; } }

.collage__left, .collage__right {
  width: 50%; }

.collage__left {
  border-right: 3px solid #fff;
  @media only screen and (max-width: 1600px) {
    width: 40%; }
  @media only screen and (max-width: 1200px) {
    display: none; }
  .image-placeholder {
    height: 100%; }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.collage__right {
  @media only screen and (max-width: 1600px) {
    width: 60%; }
  @media only screen and (max-width: 1200px) {
    width: 100%; }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.collage__right__top, .collage__right__bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  max-width: 960px;
  max-height: 480px;
  @media only screen and (max-width: 1200px) {
    max-width: 1920px; }
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    max-height: 960px; } }

.collage__right__bottom {
  @media only screen and (max-width: 768px) {
    flex-direction: column; } }

.collage__right__image {
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%; }
  .image-placeholder {
    max-width: 480px;
    height: 480px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    @media only screen and (max-width: 1440px) {
      height: 400px; }
    @media only screen and (max-width: 1200px) {
      max-width: 100%;
      height: 480px; }
    @media only screen and (max-width: 992px) {
      height: 420px; }
    @media only screen and (max-width: 768px) {
      height: 350px; }
    @media only screen and (max-width: 576px) {
     height: 250px; } } }

.collage__textblock {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5*$base-width;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    padding: 0 7*$base-width; }
  @media only screen and (max-width: 992px) {
    padding: 5*$base-width; }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10*$base-width 2.5*$base-width; }
  @media only screen and (max-width: 576px) {
    padding: 8rem 2rem;
    padding-top: 6.25rem; }

  h2 {
    padding-bottom: 2.5*$base-width;
    @media only screen and (max-width: 1350px) {
      font-size: 3.5rem; } }
  p {
    padding-bottom: 6*$base-width; } }

.button__collage {
  display: flex;
  justify-content: left; }

@media only screen and (max-width: 768px) {
  .aboutimage {
    img {
      object-position: top; } } }
