.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18.75*$base-width 0;
    @media only screen and (max-width: 1200px) {
        padding: 15*$base-width 2.5*$base-width; }
    @media only screen and (max-width: 768px) {
        padding: 12.5*$base-width 2.5*$base-width;
        padding-bottom: 0; }

    .accordion_headline {
        max-width: 1088px;
        display: flex;
        flex-direction: column;
        padding-bottom: 3*$base-width; }
    .wrapper_accordion {
        max-width: 1088px;
        width: 100%;
        @media only screen and (max-width: 1200px) {
            max-width: 1050px; }

        .accordion_tab {
            width: 100%;
            .accordion-head {
                width: 100%;
                height: 9rem;
                background-image: linear-gradient(to right, $orange, $green);
                cursor: pointer;
                font-size: 1.8rem;
                position: relative;
                margin-bottom: 2rem;
                p {
                    color: #fff;
                    padding-bottom: 0;
                    left: 3rem;
                    top: 50%;
                    transform: translate(0%, -50%);
                    position: absolute;
                    font-weight: 400; }

                svg {
                    fill: #fff;
                    position: absolute;
                    width: 2.6rem;
                    right: 2.5rem;
                    top: 50%;
                    transform: translate(-35%, -50%);
                    transition: all .4s ease-in-out;
                    &.minus {
                        opacitiy: 0; } }

                @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                    font-size: 2rem;

                    &:after {
                        font-size: 4rem; } }

                &:hover {
                    background: $green;
                    color: #000; }

                &.open {
                    background: $green;
                    svg {
                        transition: all .4s ease-in-out;
                        &.minus {
                            opacitiy: 1; }
                        &.plus {
                            opacity: 0; } } } }

            .accordion-content {
                max-height: 0;
                overflow: hidden;
                transition: max-height 1s cubic-bezier(0, 1, 0, 1);

                &.open {
                    max-height: 9999px;
                    transition: max-height 2s ease-in-out; }

                .accordion-box {
                    padding: 4rem;
                    .price {
                        padding-bottom: 1*$base-width; }
                    .green {
                        color: $green; }

                    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                        padding: 0 9*$base-width 3*$base-width 3*$base-width; } }

                .accordion__table {
                    display: flex;
                    .accordion__time {
                        max-width: 150px;
                        width: 100%; }
                    ul.pdf {
                        list-style-type: none;
                        li {
                            a {
                                color: $orange;
                                svg {
                                    fill: $orange;
                                    max-width: 15px; } }
                            &:hover {
                                a {
                                    color: $green;
                                    font-weight: 600;
                                    svg {
                                        fill: $green; } } } } } } }

            &.onyltab {
                .accordion-head {
                    &:hover {
                        background-image: linear-gradient(to right, $orange, $green);
                        cursor: context-menu; } } } } } }

#aboutme {
    .accordion__table {
        display: block;
        li {
            font-size: 2rem;
            line-height: 1.5;
            color: $gray;
            padding-bottom: 2rem;
            font-weight: 300; } } }
