header {
  width: 100%;
  height: 100px;
  margin: auto;
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000026;
  z-index: 1001;
  @media only screen and (max-width: 1700px) {
    padding: 0 20px; }
  @media only screen and (max-width: 1260px) {
    height: 80px; }
  @media only screen and (max-width: 992px) {
    height: 60px; }

  .navbar-area, .header_wrapper {
    height: 100%; } }

.site-navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1640px;
  height: 100%;
  align-items: center;
  margin: auto;
  @media only screen and (max-width: 768px) {
    max-width: 720px; }


  .logo {
    max-width: 306px;
    max-height: 76px;
    width: 100%;
    @media only screen and (max-width: 1400px) {
      max-width: 280px; }
    @media only screen and (max-width: 1200px) {
      max-width: 250px; }
    @media only screen and (max-width: 992px) {
      max-width: 200px; }

    img {
      width: 100%; } }
  .nav-menu {
    height: 100%;
    @media only screen and (max-width: 1260px) {
      background: #fff;
      height: auto;
      right: -250px;
      position: absolute;
      top: 80px;
      z-index: 99;
      transition: 0.5s ease-in-out;
      &.open {
        right: 0; } }
    @media only screen and (max-width: 992px) {
      top: 60px; }

    ul {
      display: flex;
      flex-direction: row;
      height: 100%;
      @media only screen and (max-width: 1260px) {
        flex-direction: column; }
      li {
        list-style: none;
        border-right: 0.5px solid #fff;
        height: 100%;
        @media only screen and (max-width: 1260px) {
          border-right: none; }
        a {
          height: 100%;
          display: block;
          text-transform: uppercase;
          font-size: 2rem;
          color: $gray;
          text-decoration: none;
          padding: 0 4*$base-width;
          display: flex;
          align-items: center;
          @media only screen and (max-width: 1450px) {
            padding: 0 3.5*$base-width;
            font-size: 1.6rem; }
          @media only screen and (max-width: 1260px) {
            font-size: 1.6rem;
            padding: 3.75*$base-width  5*$base-width; }
          @media only screen and (max-width: 992px) {
            font-size: 1.4rem; }
          &:hover {
            background-color: $orange;
            color: #fff;
            cursor: pointer;
            transition: all .3s ease-in-out; } }

        &.bg-orange {
          a {
            color: #fff; } } } } }

  .nav-toggler {
    padding: 5px;
    background-color: transparent;
    cursor: pointer;
    height: 39px;
    border: none;
    display: none;
    @media only screen and (max-width: 1260px) {
      display: block; }

    span, span:before, span:after {
      width: 28px;
      height: 3px;
      background-color: $orange;
      display: block;
      transition: .3s; }

    span:before {
      content: '';
      transform: translateY(-9px); }

    span:after {
      content: '';
      transform: translateY(6px); }

    &.toggler-open span {
      background-color: transparent;

      &:before {
        transform: translateY(0px) rotate(45deg); }

      &:after {
        transform: translateY(-3px) rotate(-45deg); } } } }
